import { api } from './api'
import datastore from './datastore'
/*
    I'm not sending the response direct to the view because I might want to
    add additional data, probably from the data store to what i'm sending in the frontend

    response.success
    response.data
*/

const repository = {

  adminLogin: async (email, password) => {
    const returnValue = new Object()
    const response = await api.adminLogin(email, password)
    if (response.success) {
      datastore.dispatch('user/saveAuthenticatedUser', response.data) // store the user's data in localstorage
      returnValue.success = true
      return returnValue
    }
    returnValue.success = false
    returnValue.data = response.data
    return returnValue
  },

  userAuthenticated: () => {
    return datastore.state.user.userData && datastore.getters['user/retrieveUserToken']
  },

  adminIsRider: ()=>{
    let userData = datastore.getters['user/retrieveUserData'];
    return userData.user && userData.user.admin && userData.user.admin.role.type=='rider';
  },
  adminIsLord: ()=>{
    let userData = datastore.getters['user/retrieveUserData'];
    return userData.user && userData.user.admin && (userData.user.admin.role.name.toLowerCase()=='lord' || userData.user.admin.role.name.toLowerCase()=='super admin');
  },

  async clearAuthUserData() {
    // let userData = JSON.parse(window.localStorage.getItem('user_data'))
    // await this.user.logoutUser(userData.user.id);
    window.localStorage.removeItem('user_data')
  },

  user: {
    async requestExport(data) {
      const returnValue = {}
      const response = await api.user.requestExport(data)
      if (response.success){
        returnValue.success = true
        returnValue.data = response.data
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
    },
    async searchActivityLogs(data, page=1, perpage=50) {
      const returnValue = {}
      const response = await api.user.searchActivityLogs(data, perpage, page)
      if (response.success) {
        returnValue.success = true
        returnValue.data = response.data
        returnValue.extra_data = response.extra_data
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    
    async logoutUser(userId){
      await api.user.logoutUser(userId);
    },
    async search (data, page = 1, perpage = 50) {
      const returnValue = {}
      const response = await api.searchUser(data, perpage, page)
      if (response.success){
        returnValue.success = true
        returnValue.data = response.data
        returnValue.extra_data = response.extra_data
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue



      // if (page == 1) {
      //   datastore.commit('user/clearAdminList')
      // }
      // const response = await api.searchUser(data, perpage, page)
      // if (response.success && !response.data.length) return
      // if (response.success){
      //   datastore.dispatch('user/saveAdminList', response.data)
      //   this.search(data, perpage, ++page)
      // } 
      // if (!response.success) this.search(data, perpage, page)
    },

    async createNewAdmin (data) {
      const returnValue = {}
      const response = await api.createNewAdmin(data)
      if (response.success) {
        returnValue.success = true
        this.search()
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async loadAdminData(user_id) {
      let returnValue = {};
        
      const response = await api.user.fetchAdminData(user_id);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },

    async updateAdminData (data) {
      const returnValue = {}
      const response = await api.user.updateAdminData(data)
      if (response.success) {
        returnValue.success = true
        returnValue.data = response.data;
        // datastore.dispatch('user/saveAuthenticatedUser', response.data)
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    async updateRiderRegion (data) {
      const returnValue = {}
      const response = await api.user.updateRiderRegion(data)
      if (response.success) {
        returnValue.success = true
        returnValue.data = response.data;
        // datastore.dispatch('user/saveAuthenticatedUser', response.data)
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async loadAdminProfile() {
      const returnValue = {};
      const response = await api.user.loadAdminProfile();
      if (response.success) {
        returnValue.success = true;
        returnValue.data = response.data;
        datastore.commit('user/clearAdminProfile');
        datastore.dispatch('user/saveAdminProfile', response.data);
        return returnValue;
      }
      returnValue.success = false;
      returnValue.data = response.data;
      return returnValue;
    },

    async updateProfile(data) {
      const returnValue = {}
      const response = await api.user.updateProfile(data)
      if (response.success) {
        returnValue.success = true;
        returnValue.data = response.data;
        console.log("profile update daa is ", response.data)
        datastore.commit('user/clearAdminProfile')
        datastore.dispatch('user/saveAdminProfile', response.data)
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async createNewCustomer (data) {
      const returnValue = {}
      const response = await api.customer.createNewCustomer(data)
      if (response.success) {
        returnValue.success = true
        this.fetchCustomers()
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async completeRegistration (data) {
      const returnValue = {}
      const response = await api.completeRegistration(data)
      if (response.success) {
        returnValue.success = true
        datastore.dispatch('user/saveAuthenticatedUser', response.data)
        return returnValue
      }
      returnValue.success = false
      returnValue.data=response.data;
      return returnValue
    },

    async resetPassword (data) {
      const returnValue = {}
      const response = await api.resetPassword(data)
      if (response.success) {
        returnValue.success = true
        returnValue.data = response.data;
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async completePasswordReset (data) {
      const returnValue = {}
      const response = await api.completePasswordReset(data)
      if (response.success) {
        returnValue.success = true
        datastore.dispatch('user/saveAuthenticatedUser', response.data)
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async fetchCustomers(data, page = 1, perpage = 50) {
      const returnValue = {}
      const response = await api.customer.fetchCustomers(data, perpage, page)
      if (response.success){
        returnValue.success = true
        returnValue.data = response.data
        returnValue.extra_data = response.extra_data
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async updateCustomerData (data) {
      const returnValue = {}
      const response = await api.customer.updateCustomerData(data)
      if (response.success) {
        returnValue.success = true
        returnValue.data = response.data;
        // datastore.dispatch('user/saveAuthenticatedUser', response.data)
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    
    
  },

  role: {
    async loadAdminRolesToStore () {
      const response = await api.getAdminRoles()
      if (response.success) datastore.dispatch('role/saveAdminRoles', response.data)
      // else setTimeout(this.loadAdminRolesToStore(), 10000)
    }
  },

  regions: {

    async createNewCountry (data) {
      const returnValue = {}
      const response = await api.regions.createNewCountry(data)
      if (response.success) {
        returnValue.success = true
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async createNewState (data) {
      const returnValue = {}
      const response = await api.regions.createNewState(data)
      if (response.success) {
        returnValue.success = true
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    async createNewCity (data) {
      const returnValue = {}
      const response = await api.regions.createNewCity(data)
      if (response.success) {
        returnValue.success = true
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    async fetchCountries(data, type=null, nomenclatureID=null, subscriptionID=null){
      const returnValue = {};
      const response = await api.regions.fetchCountries(data, type, nomenclatureID, subscriptionID);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        // datastore.commit('region/clearCountryList')
        // datastore.dispatch('region/saveCountryList', response.data)
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },
    async fetchStates(data, type=null, nomenclatureID=null, subscriptionID=null){
      const returnValue = {};
      const response = await api.regions.fetchStates(data, type, nomenclatureID, subscriptionID);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        // datastore.commit('region/clearStateList')
        // datastore.dispatch('region/saveStateList', response.data)
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },
    async fetchCities(data, type=null, nomenclatureID=null, subscriptionID=null){
      const returnValue = {};
      const response = await api.regions.fetchCities(data, type, nomenclatureID, subscriptionID);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        // datastore.commit('region/clearCityList')
        // datastore.dispatch('region/saveCityList', response.data)
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },








    async loadNomenclatureList(bound, perpage=5, page=1) {
      let returnValue = {};

      if (page == 1) {
        datastore.commit('region/clearNomenclatureList')
      }
      const response = await api.regions.loadNomenclatureList(bound, perpage, page);
      console.log("load nomenclature list response is ", response)
      if (response.success && !response.data.length) return
      if (response.success){
        returnValue.success=true;
        returnValue.data = response.data;
        datastore.dispatch('region/saveNomenclatureList', response.data)
        this.loadNomenclatureList(bound, perpage, ++page)
        return returnValue;
      } 
      returnValue.success=false;
      returnValue.data = response.data;
      return returnValue;
    },



    async createNewInterCountryNomenclature(data){
      const returnValue = {}
      const response = await api.regions.createNewInterCountryNomenclature(data)
      if (response.success) {
        returnValue.success = true
        this.loadInterCountryNomenclatureList();
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    async loadInterCountryNomenclatureList(perpage=50, page=1){
      if (page == 1) {
        datastore.commit('region/clearInterCountryNomenclatureList')
      }
      const response = await api.regions.loadInterCountryNomenclatureList(perpage, page);
      if (response.success && !response.data.length) return
      if (response.success){
        datastore.dispatch('region/saveInterCountryNomenclatureList', response.data)
        this.loadInterCountryNomenclatureList(perpage, ++page)
      } 
    },
    async updateInterCountryNomenclature(data){
      const returnValue = {}
      const response = await api.regions.updateInterCountryNomenclature(data)
      if (response.success) {
        returnValue.success = true
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },



    async createNewInterStateNomenclature(data){
      const returnValue = {}
      const response = await api.regions.createNewInterStateNomenclature(data)
      if (response.success) {
        returnValue.success = true
        this.loadInterStateNomenclatureList();
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    async loadInterStateNomenclatureList(perpage=50, page=1){
      if (page == 1) {
        datastore.commit('region/clearInterStateNomenclatureList')
      }
      const response = await api.regions.loadInterStateNomenclatureList(perpage, page);
      console.log("loading interstate response is ", response)
      if (response.success && !response.data.length) return
      if (response.success){
        datastore.commit('region/saveInterStateNomenclatureList', response.data)
        this.loadInterStateNomenclatureList(perpage, ++page)
      } 
    },
    async updateInterStateNomenclature(data){
      const returnValue = {}
      const response = await api.regions.updateInterStateNomenclature(data)
      if (response.success) {
        returnValue.success = true
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },



    async createNewInterCityNomenclature(data){
      const returnValue = {}
      const response = await api.regions.createNewInterCityNomenclature(data)
      if (response.success) {
        returnValue.success = true
        this.loadInterCityNomenclatureList()
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    async loadInterCityNomenclatureList(perpage=50, page=1){
      if (page == 1) {
        datastore.commit('region/clearInterCityNomenclatureList')
      }
      const response = await api.regions.loadInterCityNomenclatureList(perpage, page);
      if (response.success && !response.data.length) return
      if (response.success){
        datastore.commit('region/saveInterCityNomenclatureList', response.data)
        this.loadInterCityNomenclatureList(perpage, ++page)
      } 
    },
    async updateInterCityNomenclature(data){
      const returnValue = {}
      const response = await api.regions.updateInterCityNomenclature(data)
      if (response.success) {
        returnValue.success = true
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },





    async deleteInterCityNomenclature(nomenclatureID){
      const returnValue = {}
      const response = await api.regions.deleteNomenclature(nomenclatureID)
      if (response.success) {
        returnValue.success = true
        this.loadInterCityNomenclatureList();
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async deleteInterStateNomenclature(nomenclatureID){
      const returnValue = {}
      const response = await api.regions.deleteNomenclature(nomenclatureID)
      if (response.success) {
        returnValue.success = true
        this.loadInterStateNomenclatureList();
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async deleteInterCountryNomenclature(nomenclatureID){
      const returnValue = {}
      const response = await api.regions.deleteNomenclature(nomenclatureID)
      if (response.success) {
        returnValue.success = true
        this.loadInterCountryNomenclatureList();
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },














    

    async queryPriceData(data){
      const returnValue = {};
      const response = await api.regions.queryPriceData(data);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },







    async setCountryPickup(data) {
      const returnValue = {};
      const response = await api.regions.setCountryPickup(data);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },
    async setStatePickup(data) {
      const returnValue = {};
      const response = await api.regions.setStatePickup(data);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },
    async setCityPickup(data) {
      const returnValue = {};
      const response = await api.regions.setCityPickup(data);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },

    async setCountryDelivery(data) {
      const returnValue = {};
      const response = await api.regions.setCountryDelivery(data);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },
    async setStateDelivery(data) {
      const returnValue = {};
      const response = await api.regions.setStateDelivery(data);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },
    async setCityDelivery(data) {
      const returnValue = {};
      const response = await api.regions.setCityDelivery(data);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },








    





    






    







    
    
    
    async createNewWeight (data) {
      const returnValue = {}
      const response = await api.regions.createNewWeight(data)
      if (response.success) {
        returnValue.success = true
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    async loadWeightList(perpage=1, page=1){
      if (page == 1) {
        datastore.commit('region/clearWeightList')
      }
      const response = await api.regions.loadWeightList(perpage, page);
      if (response.success && !response.data.length) return
      if (response.success){
        datastore.dispatch('region/saveWeightList', response.data)
        this.loadWeightList(perpage, ++page)
      } 
    },
    async fetchSupportedWeightList(){
      const returnValue = {}
      const response = await api.regions.loadWeightList(0, 0);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      } 
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },
    
    /*
    async loadInterStateNomenclatureList(){
      const returnValue = {};
      const response = await api.regions.loadInterStateNomenclatureList();
      console.log("from repository, interState nomenclature is ", response);
      if (response.success){
        returnValue.success=true;
        datastore.dispatch('region/saveInterStateNomenclatureList', response.data)
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },
    async loadInterCountryNomenclatureList(){
      const returnValue = {};
      const response = await api.regions.loadInterCountryNomenclatureList();
      console.log("from repository, interCountry nomenclature is ", response);
      if (response.success){
        returnValue.success=true;
        datastore.dispatch('region/saveInterCountryNomenclatureList', response.data)
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },
    */

   
   
   
   
   
    async createNewInterCityPriceList(data){
      const returnValue = {}
      const response = await api.regions.createNewInterCityPriceList(data)
      if (response.success) {
        returnValue.success = true
        this.loadInterCityPriceList();
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
    return returnValue
    },
    async createNewInterStatePriceList(data){
    const returnValue = {}
    const response = await api.regions.createNewInterStatePriceList(data)
    if (response.success) {
      returnValue.success = true
      this.loadInterStatePriceList();
      return returnValue
    }
    returnValue.success = false
    returnValue.data = response.data
    return returnValue
    },
    async createNewInterCountryPriceList(data){
    const returnValue = {}
    const response = await api.regions.createNewInterCountryPriceList(data)
    if (response.success) {
      returnValue.success = true
      this.loadInterCountryPriceList();
      return returnValue
    }
    returnValue.success = false
    returnValue.data = response.data
    return returnValue
    },

    async loadInterCityPriceList(perpage=10, page=1){
      if (page == 1) {
        datastore.commit('region/clearInterCityPriceList')
      }
      const response = await api.regions.loadInterCityPriceList(perpage, page);
      if (response.success && !response.data.length) return
      if (response.success){
        datastore.dispatch('region/saveInterCityPriceList', response.data)
        // this.loadInterCityPriceList(perpage, ++page)
      } 
    },
    async loadInterStatePriceList(perpage=10, page=1){
      if (page == 1) {
        datastore.commit('region/clearInterStatePriceList')
      }
      const response = await api.regions.loadInterStatePriceList(perpage, page);
      if (response.success && !response.data.length) return
      if (response.success){
        datastore.dispatch('region/saveInterStatePriceList', response.data)
        // this.loadInterStateNomenclatureList(perpage, ++page)
      } 
    },

    async loadInterCountryPriceList(perpage=10, page=1){
      if (page == 1) {
        datastore.commit('region/clearInterCountryPriceList')
      }
      const response = await api.regions.loadInterCountryPriceList(perpage, page);
      if (response.success && !response.data.length) return
      if (response.success){
        datastore.dispatch('region/saveInterCountryPriceList', response.data)
        // this.loadInterCountryNomenclatureList(perpage, ++page)
      } 
    },


    async updateInterCityPriceList(data){
      const returnValue = {}
      const response = await api.regions.updateInterCityPriceList(data)
      if (response.success) {
        returnValue.success = true
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async updateInterStatePriceList(data){
      const returnValue = {}
      const response = await api.regions.updateInterStatePriceList(data)
      if (response.success) {
        returnValue.success = true
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    async updateInterCountryPriceList(data){
      const returnValue = {}
      const response = await api.regions.updateInterCountryPriceList(data)
      if (response.success) {
        returnValue.success = true
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },



    async deleteInterCityPriceList(data){
      const returnValue = {}
      const response = await api.regions.deleteInterCityPriceList(data)
      if (response.success) {
        returnValue.success = true
        this.loadInterCityPriceList();
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async deleteInterStatePriceList(data){
      const returnValue = {}
      const response = await api.regions.deleteInterStatePriceList(data)
      if (response.success) {
        returnValue.success = true
        this.loadInterStatePriceList();
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    async deleteInterCountryPriceList(data){
      const returnValue = {}
      const response = await api.regions.deleteInterCountryPriceList(data)
      if (response.success) {
        returnValue.success = true
        this.loadInterCountryPriceList();
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },






    async deleteWeight(weightID){
      const returnValue = {}
      const response = await api.regions.deleteWeight(weightID)
      if (response.success) {
        returnValue.success = true
        this.loadWeightList();
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },


    async deleteCountry(countryID){
      const returnValue = {}
      const response = await api.regions.deleteCountry(countryID)
      if (response.success) {
        returnValue.success = true
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    async deleteState(stateID){
      const returnValue = {}
      const response = await api.regions.deleteState(stateID)
      if (response.success) {
        returnValue.success = true
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    async deleteCity(cityID){
      const returnValue = {}
      const response = await api.regions.deleteCity(cityID)
      if (response.success) {
        returnValue.success = true
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    






  },

  order: {
    async requestExport(data) {
      const returnValue = {}
      const response = await api.order.requestExport(data)
      if (response.success){
        returnValue.success = true
        returnValue.data = response.data
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
    },

    async loadStatistics(orderStatus=null){
      const returnValue = {};
      const response = await api.order.loadStatistics(orderStatus);
      if (response.success){
        returnValue.success=true;
        returnValue.data = response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },

    async loadOrders(data, page=1, perpage=50){
        // if (page == 1) {
        //   datastore.commit('order/clearOrderList')
        // }

        // const response = await api.order.loadOrders(data, perpage, page);
        // if (response.success && (response.data==null || response.data.length==0)) return;
        // if (response.success){
        //   datastore.dispatch('order/saveOrderList', response.data);
        //   this.loadOrders(data, perpage, ++page);
        // }
      
        const returnValue = {}
        const response = await api.order.loadOrders(data, perpage, page)
        if (response.success){
          returnValue.success = true
          returnValue.data = response.data
          returnValue.extra_data = response.extra_data
          return returnValue
        }
        returnValue.success = false
        returnValue.data = response.data
        return returnValue
    },

    async loadOrder(orderID){
      const returnValue = {};
      const response = await api.order.loadOrder(orderID);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },

    async loadCustomerOrderStatistics(userID, orderStatus=null){
      const returnValue = {};
      datastore.commit('order/clearCustomerOrderStatistics')
      const response = await api.order.loadCustomerOrderStatistics(userID, orderStatus);
      if (response.success){
        returnValue.success=true;
        datastore.dispatch('order/saveCustomerOrderStatistics', response.data);
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data
      return returnValue;
    },
    async loadCustomerOrders(data, perpage=10, page=1){
      if (page == 1) {
        datastore.commit('order/clearOrderList')
      }

      const response = await api.order.loadOrders(data, perpage, page);
      if (response.success && (response.data==null || response.data.length==0)) return;
      if (response.success){
        datastore.dispatch('order/saveOrderList', response.data);
        this.loadCustomerOrders(data, perpage, ++page);
      } 
    },

    async loadSpecificCustomerOrder(data, perpage=10, page=1){
      if (page == 1) {
        datastore.commit('order/clearCustomerOrderList')
      }

      const response = await api.order.loadSpecificCustomerOrder(data, perpage, page);
      if (response.success && (response.data==null || response.data.length==0)) return;
      if (response.success){
        datastore.dispatch('order/saveCustomerOrderList', response.data);
        this.loadSpecificCustomerOrder(data, perpage, ++page);
      } 
    },


    
    




    async loadRiderOrderStatistics(userID, orderStatus=null){
      const returnValue = {};
      datastore.commit('order/clearRiderOrderStatistics')
      const response = await api.order.loadRiderOrderStatistics(userID, orderStatus);
      if (response.success){
        returnValue.success=true;
        datastore.dispatch('order/saveRiderOrderStatistics', response.data);
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data
      return returnValue;
    },

    async loadSpecificRiderOrder(data, perpage=10, page=1){
      if (page == 1) {
        datastore.commit('order/clearRiderOrderList')
      }

      const response = await api.order.loadSpecificRiderOrder(data, perpage, page);
      if (response.success && (response.data==null || response.data.length==0)) return;
      if (response.success){
        datastore.dispatch('order/saveRiderOrderList', response.data);
        this.loadSpecificRiderOrder(data, perpage, ++page);
      } 
    },



    async createNewOrder (data) {
      const returnValue = {}
      const response = await api.order.createNewOrder(data)
      if (response.success) {
        returnValue.success = true
        returnValue.data = response.data
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async confirmOrder (data) {
      const returnValue = {}
      const response = await api.order.confirmOrder(data)
      if (response.success) {
        returnValue.success = true
        returnValue.data = response.data
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async updateOrder (data) {
      const returnValue = {}
      const response = await api.order.updateOrder(data)
      if (response.success) {
        returnValue.success = true
        returnValue.data = response.data
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async updateBatchOrder (data) {
      const returnValue = {}
      const response = await api.order.updateBatchOrder(data)
      if (response.success) {
        returnValue.success = true
        returnValue.data = response.data
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },

    async uploadExcelFile(excelFile){
      
    },


    

  },

  customer: {

    async requestExport(data) {
      const returnValue = {}
      const response = await api.customer.requestExport(data)
      if (response.success){
        returnValue.success = true
        returnValue.data = response.data
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
    },
    
    async loadCustomerData(user_id) {
      let returnValue = {};
      
      const response = await api.customer.fetchCustomerData(user_id);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },

    async loadCustomerApiAccess(user_id) {
      let returnValue = {};
      
      const response = await api.customer.fetchCustomerApiAccess(user_id);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },

    async activateApi(userID) {
      let returnValue = {};
      
      const response = await api.customer.activateApi(userID);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },

    async deactivateApi(userID) {
      let returnValue = {};
      
      const response = await api.customer.deactivateApi(userID);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },

    async updateCustomerAPIAccess (data) {
      const returnValue = {}
      const response = await api.customer.updateCustomerAPIAccess(data)
      if (response.success) {
        returnValue.success = true
        // returnValue.data = response.data
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
  },

  subscription: {
    async createNewSubscription (data) {
      const returnValue = {}
      const response = await api.subscription.createNewSubscription(data)
      if (response.success) {
        returnValue.success = true
        datastore.commit('subscription/clearSubscriptionList')
        datastore.dispatch('subscription/saveSubscriptionList', response.data)
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    async updateSubscription (data) {
      const returnValue = {}
      const response = await api.subscription.updateSubscription(data)
      if (response.success) {
        returnValue.success = true
        datastore.commit('subscription/clearSubscriptionList')
        datastore.dispatch('subscription/saveSubscriptionList', response.data)
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
    async fetchSubscriptions(){
      const returnValue = {};
      const response = await api.subscription.fetchSubscriptions();
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        datastore.commit('subscription/clearSubscriptionList')
        datastore.dispatch('subscription/saveSubscriptionList', response.data)
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },

    async fetchSubscription(subscriptionID){
      const returnValue = {};
      const response = await api.subscription.fetchSubscription(subscriptionID);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },

    async loadCustomerSubscriptions(data, perpage=10, page=1){
      if (page == 1) {
        datastore.commit('subscription/clearCustomerSubscriptions')
      }

      const response = await api.subscription.loadCustomerSubscriptions(data, perpage, page);
      if (response.success && (response.data==null || response.data.length==0)) return;
      if (response.success){
        datastore.dispatch('subscription/saveCustomerSubscriptions', response.data);
        this.loadCustomerSubscriptions(data, perpage, ++page);
      } 
    },

    async loadSubscribedCustomers(data, perpage=10, page=1){
      if (page == 1) {
        datastore.commit('subscription/clearCurrentSubscribedCustomersForASubscription')
      }

      const response = await api.subscription.loadSubscribedCustomers(data, perpage, page);
      if (response.success && (response.data==null || response.data.length==0)) return;
      if (response.success){
        datastore.dispatch('subscription/saveCurrentSubscribedCustomersForASubscription', response.data);
        this.loadSubscribedCustomers(data, perpage, ++page);
      } 
    },

    async loadCustomerSubscriptionInformation(userID){
      let returnValue = {};

      const response = await api.subscription.loadCustomerSubscriptionInformation(userID);
      if (response.success){
        returnValue.success=true;
        returnValue.data = response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data = response.data;
      return returnValue;
    },



    async loadSubscriptionHistory(data, perpage=10, page=1){
      if (page == 1) {
        datastore.commit('subscription/clearHistoryForASubscription')
      }

      const response = await api.subscription.loadSubscriptionHistory(data, perpage, page);
      if (response.success && (response.data==null || response.data.length==0)) return;
      if (response.success){
        datastore.dispatch('subscription/saveHistoryForASubscription', response.data);
        this.loadSubscriptionHistory(data, perpage, ++page);
      } 
    },

    async renewCustomerSubscriptions(data){
      const returnValue = {}
      const response = await api.subscription.renewCustomerSubscriptions(data)
      if (response.success) {
        returnValue.success = true
        returnValue.data = response.data
        this.loadCustomerSubscriptions(data);
        // datastore.commit('subscription/clearCustomerSubscriptions')
        // datastore.dispatch('subscription/saveCustomerSubscriptions', response.data)
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue 
    },

    async subscribeUser(data) {
      const returnValue = {}
      const response = await api.subscription.subscribeUser(data)
      if (response.success) {
        returnValue.success = true
        returnValue.data = response.data
        this.loadCustomerSubscriptions(data);
        // datastore.commit('subscription/clearCustomerSubscriptions')
        // datastore.dispatch('subscription/saveCustomerSubscriptions', response.data)
        return returnValue
      }
      returnValue.success = false
      returnValue.data = response.data
      return returnValue
    },
  },

  transaction: {
    async loadCustomerWalletHistory(data, perpage=10, page=1){
      if (page == 1) {
        datastore.commit('transaction/clearCustomerWalletHistory')
      }

      const response = await api.transaction.loadCustomerWalletHistory(data, perpage, page);
      if (response.success && (response.data==null || response.data.history.length==0)) return;
      if (response.success){
        datastore.dispatch('transaction/saveCustomerWalletHistory', response.data);
        this.loadCustomerWalletHistory(data, perpage, ++page);
      } 
    },

    async topupWallet(data){
      const returnValue = {};
      const response = await api.transaction.topupWallet(data);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },
    async confirmWalletTopup(data){
      const returnValue = {};
      const response = await api.transaction.confirmWalletTopup(data);
      if (response.success){
        returnValue.success=true;
        returnValue.data=response.data;
        return returnValue;
      }
      returnValue.success=false;
      returnValue.data=response.data;
      return returnValue;
    },

  },

}

export default repository
